var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "transition": "fade-transition",
      "nudge-bottom": _vm.hideDetails ? 5 : -20,
      "min-width": !_vm.rangeMenu ? '290px' : undefined,
      "offset-y": "",
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "value": _vm.dateText,
            "label": _vm.label,
            "placeholder": _vm.placeholder,
            "append-icon": "date_range",
            "dense": "",
            "flat": "",
            "solo": _vm.solo,
            "outlined": _vm.outlined,
            "readonly": "",
            "clearable": _vm.clearable,
            "rules": _vm.rules,
            "hide-details": _vm.hideDetails,
            "disabled": _vm.disabled
          },
          on: {
            "click:clear": _vm.onClear
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_vm.menu ? _c('v-fade-transition', [_c('v-card', {
    attrs: {
      "color": "primaryBackgroundColor"
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-wrap pa-0"
  }, [_vm.rangeMenu ? _c('v-list', {
    staticClass: "flex-grow-1",
    attrs: {
      "width": "150px"
    }
  }, [_c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'today',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('today');
      }
    }
  }, [_vm._v("Today")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'yesterday',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('yesterday');
      }
    }
  }, [_vm._v("Yesterday")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'last_7_days',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('last_7_days');
      }
    }
  }, [_vm._v("Last 7 Days")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'last_30_days',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('last_30_days');
      }
    }
  }, [_vm._v("Last 30 Days")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'this_month',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('this_month');
      }
    }
  }, [_vm._v("This Month")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'last_month',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('last_month');
      }
    }
  }, [_vm._v("Last Month")]), _c('v-list-item', {
    attrs: {
      "input-value": _vm.dateRange === 'custom_range',
      "color": "primary",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.changeDateRange('custom_range');
      }
    }
  }, [_vm._v("Custom Range")])], 1) : _vm._e(), _c('v-date-picker', {
    ref: "datePicker",
    class: ['app-date-picker', 'pb-3', {
      'flex-grow-1': _vm.rangeMenu
    }],
    attrs: {
      "min": _vm.min,
      "max": _vm.max,
      "color": "primary",
      "range": _vm.range || _vm.rangeMenu
    },
    on: {
      "change": function ($event) {
        _vm.$emit('change', $event);

        _vm.save($event);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }