var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "app-file-field",
    attrs: {
      "autocomplete": "off",
      "type": "file",
      "accept": _vm.accept,
      "solo": "",
      "flat": "",
      "outlined": "",
      "height": _vm.height,
      "disabled": _vm.disabled,
      "error-messages": _vm.file.error,
      "rules": _vm.file.model ? [] : _vm.rules
    },
    nativeOn: {
      "input": function ($event) {
        return _vm.inputFile.apply(null, arguments);
      }
    }
  }, [_vm._t("default", function () {
    return [_vm.file.model ? _c('v-card', {
      staticClass: "pa-5",
      attrs: {
        "flat": "",
        "color": "transparent",
        "width": "100%",
        "disabled": _vm.disabled
      }
    }, [_c('v-card-text', {
      staticClass: "file-holder flex-column flex-md-row"
    }, [_c('v-icon', {
      staticClass: "primary--text px-4"
    }, [_vm._v("mdi-file")]), _c('div', {
      staticClass: "text-md-left py-4 px-2 pl-md-0"
    }, [_c('p', {
      staticClass: "ma-0 text-body-1"
    }, [_vm._v("Added File")]), _c('p', {
      staticClass: "ma-0 primaryTextColor--text"
    }, [_vm._v(_vm._s(_vm.file.model.name))])]), _c('v-spacer'), _c('div', {
      staticClass: "d-flex flex-column flex-md-row"
    }, [_c('v-btn', {
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [_vm._v("Replace file")])], 1)], 1)], 1) : _c('v-card', {
      staticClass: "pa-5",
      attrs: {
        "flat": "",
        "color": "transparent",
        "disabled": _vm.disabled
      }
    }, [_c('v-card-text', {
      staticClass: "text-center"
    }, [_c('v-icon', {
      staticClass: "primary--text"
    }, [_vm._v("mdi-file")])], 1), _c('v-card-actions', [_c('v-btn', {
      staticClass: "primary--text",
      attrs: {
        "rounded": "",
        "outlined": ""
      }
    }, [_vm._v("Upload File")])], 1)], 1)];
  }, {
    "slot": "prepend-inner"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }