import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// Auth View
const AuthView = () => import(/* webpackChunkName: "auth-view" */ '@/views/AuthView');
const Login = () => import(/* webpackChunkName: "auth-view/login" */ '@/components/Views/AuthView/Login');
const Otp = () => import(/* webpackChunkName: "auth-view/otp" */ '@/components/Views/AuthView/Otp');
const SetPassword = () =>
  import(/* webpackChunkName: "auth-view/set-password" */ '@/components/Views/AuthView/SetPassword');

// Main View
const MainView = () => import(/* webpackChunkName: "main-view" */ '@/views/MainView');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/components/Views/MainView/Dashboard/Index');
const UserManagement = () =>
  import(/* webpackChunkName: "user-management" */ '@/components/Views/MainView/UserManagement/Index');
const UserManagementForm = () =>
  import(
    /* webpackChunkName: "user-management/form" */ '@/components/Views/MainView/UserManagement/Forms/UserForm/Index'
  );
const UserManagementEdit = () =>
  import(
    /* webpackChunkName: "user-management/form" */ '@/components/Views/MainView/UserManagement/Forms/UserForm/EditForm'
  );
const UserManagementGroup = () =>
  import(/* webpackChunkName: "user-management/form" */ '@/components/Views/MainView/UserManagement/Group/Index');
const UserManagementGroupForm = () =>
  import(/* webpackChunkName: "user-management/form" */ '@/components/Views/MainView/UserManagement/Group/GroupForm');
const UserManagementGroupEdit = () =>
  import(/* webpackChunkName: "user-management/form" */ '@/components/Views/MainView/UserManagement/Group/EditGroup');
const Transactions = () =>
  import(/* webpackChunkName: "transactions" */ '@/components/Views/MainView/Transactions/Index');
// const Settlement = () => import(/* webpackChunkName: "settlement" */ '@/components/Views/MainView/Settlement/Index');
const Bills = () => import(/* webpackChunkName: "settlement" */ '@/components/Views/MainView/Bills/Index');

const mainViewRedirect = { name: 'transactions' };
const routes = [
  {
    path: '/auth',
    name: 'auth-view',
    redirect: { name: 'login' },
    component: AuthView,
    meta: { requiresAuthentication: false },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { requiresAuthentication: false },
      },
      {
        path: 'otp',
        name: 'otp',
        component: Otp,
        meta: { requiresAuthentication: false },
      },
      {
        path: 'set-password',
        name: 'set-password',
        component: SetPassword,
        meta: { requiresAuthentication: false },
      },
    ],
  },
  {
    path: '/',
    name: 'main-view',
    redirect: mainViewRedirect,
    component: MainView,
    meta: { requiresAuthentication: true },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: UserManagement,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management/form',
        name: 'user-management-form',
        component: UserManagementForm,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management/:id',
        name: 'user-management-edit',
        component: UserManagementEdit,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management/group',
        name: 'user-management-group',
        component: UserManagementGroup,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management/group/form',
        name: 'user-management-group-form',
        component: UserManagementGroupForm,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'user-management/group/edit',
        name: 'user-management-group-edit',
        component: UserManagementGroupEdit,
        meta: { requiresAuthentication: true },
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: Transactions,
        meta: { requiresAuthentication: true },
      },
      // {
      //   path: 'settlement',
      //   name: 'settlement',
      //   component: Settlement,
      //   meta: { requiresAuthentication: true },
      // },
      {
        path: 'bills',
        name: 'bills',
        component: Bills,
        meta: { requiresAuthentication: true },
      },
    ],
  },
  // {
  //   path: '/template',
  //   name: 'template',
  //   // route level code-splitting
  //   // this generates a separate chunk (template.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "template" */ '@/views/template')
  // },
  {
    // For 404 Page
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: { path: '/' },
    // component: () => import(/* webpackChunkName: "template" */ '@/views/PathNotFound')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, from, next);

  router.app.$api.main.cancelCurrentApiCall();

  const token = router.app.$cookies.get('token');

  // console.log(token);

  if (to.meta.requiresAuthentication) {
    if (token) {
      if (!router.app.$api.main.getAuthorization()) {
        router.app.$api.main.setAuthorization(window.atob(token));
      } // Decode Base64
      next();
    } else {
      router.app.$cookies.remove('token');
      router.app.$cookies.remove('user-info');
      router.app.$api.main.setAuthorization(undefined);
      window.localStorage.setItem('isLogin', 0);
      next({ name: 'login', query: { redirect: to.name != mainViewRedirect.name ? to.name : undefined } });
    }
  } else {
    if (to.matched.length == 0) {
      next();
    } else if (token) {
      if (!router.app.$api.main.getAuthorization()) {
        router.app.$api.main.setAuthorization(window.atob(token));
      } // Decode Base64
      next({ name: 'main-view' });
    } else {
      router.app.$cookies.remove('token');
      router.app.$cookies.remove('user-info');
      window.localStorage.setItem('isLogin', 0);
      next();
    }
  }
});

export default router;
