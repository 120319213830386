var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "id": "app-confirm",
      "scrollable": "",
      "width": "400",
      "max-width": "auto",
      "color": "primaryBackgroundColor",
      "transition": "scroll-y-reverse-transition",
      "persistent": ""
    },
    model: {
      value: _vm.isShow,
      callback: function ($$v) {
        _vm.isShow = $$v;
      },
      expression: "isShow"
    }
  }, [_c('v-card', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          staticClass: "dialog-close",
          attrs: {
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.cancel
          }
        }, on), [_vm._v("close")])];
      }
    }])
  }, [_c('span', [_vm._v("Close")])]), _c('v-card-title', {
    staticClass: "font-size-18 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-card-text', {
    staticClass: "px-6 py-4"
  }, [_vm._t("default", function () {
    return [_c('p', {
      staticClass: "font-size-14 ma-0"
    }, [_vm._v(_vm._s(_vm.message))])];
  })], 2), _c('v-card-actions', [_c('v-row', {
    staticClass: "flex-row-reverse pb-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "md": "5",
      "cols": "12"
    }
  }, [_c('v-btn', {
    class: ['confirm-btn', 'btn-min-width', ((_vm.buttonTextColor) + "--text")],
    attrs: {
      "block": "",
      "depressed": "",
      "color": _vm.buttonBGColor,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "md": "5",
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "btn-min-width",
    attrs: {
      "block": "",
      "text": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }