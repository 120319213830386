import Vue from 'vue';
import Vuex from 'vuex';

import authentication from './modules/authentication';
import user from './modules/user';
import transactions from './modules/transactions';
import settlement from './modules/settlement';
import bills from './modules/bills';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  state: {},
  mutations: {},
  actions: {
    clearState(context) {
      context.commit('authentication/CLEAR_STATE');
      context.commit('user/CLEAR_STATE');
      context.commit('transactions/CLEAR_STATE');
      context.commit('settlement/CLEAR_STATE');
    },
    error(context, payload) {
      // console.log(payload.response);

      // 200 = Success
      // 400 = Bad Request
      // 401 = Authorization Failed
      // 403 = Access Denied
      // 404 = Entity Not Found
      // 419 = Token Expired
      // 422 = Parameter Error
      // 500 = Server Error

      if (payload.response && /^401$|^403$|^419$/ig.test(payload.response.status)) {
        this._vm.$eventBus.$emit('clear-data');
      }
    }
  },
  modules: {
    authentication,
    user,
    transactions,
    settlement,
    bills
  }
});

export default store;