'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    settlement_list: {}
  },
  getters: {
    getSettlementList: state => state.settlement_list
  },
  mutations: {
    SET_SETTLEMENT_LIST(state, payload) {
        state.settlement_list = payload;
    },
    CLEAR_STATE(state) {
        state.settlement_list = {};
    }
  },
  actions: {
    fetchSettlement(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi.getData({ url: '/settlement', config, apiCancelToken }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_SETTLEMENT_LIST', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_SETTLEMENT_LIST', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    // fetchReports(context, payload) {
    //   const { config, apiCancelToken } = payload || {};
    //   return new Promise((resolve, reject) => {
    //     mainApi.getData({ url: '/v2/transaction/report', config, apiCancelToken }).then(
    //       (response) => {
    //         // console.log(response);
    //         resolve(response);
    //       }
    //     ).catch(
    //       (error) => {
    //         // console.log(error);
    //         context.dispatch('error', error, { root: true });
    //         reject(error);
    //       }
    //     );
    //   });
    // }
  }
};