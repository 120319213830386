'use strict';

import mainApi from '@/assets/js/api/modules/main';
import { DeviceUUID } from 'device-uuid';
// import { uuidv7 } from 'uuid';
import { uuid } from 'vue-uuid';

export default {
  namespaced: true,
  state: {
    login_info: {},
    token: undefined,
    user_info: {},
  },
  getters: {
    getLoginInfo: (state) => state.login_info,
    getToken: (state) => state.token,
    getUserInfo: (state) => state.user_info,
  },
  mutations: {
    SET_LOGIN_INFO(state, payload) {
      state.login_info = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER_INFO(state, payload) {
      state.user_info = payload;
    },
    CLEAR_STATE(state) {
      state.login_info = {};
      state.token = undefined;
      state.user_info = {};
    },
  },
  actions: {
    setToken(context, payload) {
      context.commit('SET_TOKEN', payload);
    },
    setUserInfo(context, payload) {
      context.commit('SET_USER_INFO', payload);
    },
    login(context, payload) {
      const { data, config, apiCancelToken } = payload || {};

      let name = "mp_uuid=";
      let ca = document.cookie.split(';');
      var user_uuid

      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          user_uuid = c.substring(name.length, c.length);
        }
      }
      
      if(user_uuid){
        
        data.uuid = user_uuid

      }else{
        user_uuid = uuid.v1()
        document.cookie = "mp_uuid=" + user_uuid;
        data.uuid = user_uuid
      }

      return new Promise((resolve, reject) => {
        
        mainApi
          .postData({ url: '/auth/login', data, config, apiCancelToken })
          .then((response) => {
            context.commit('SET_LOGIN_INFO', { ...data, number: response?.data?.mobile });
            
            context.commit('SET_USER_INFO', response.data);
            
              if (!response.data.tempPassword && response.data.uuidExist) {
                context.commit('SET_TOKEN', response.data.token);
                //context.commit('SET_USER_INFO', response.data);
                this._vm.$cookies.set('token', window.btoa(response.data.token)); // Encode To Base64
                this._vm.$cookies.set('user-info', window.btoa(JSON.stringify(response.data))); // Encode To Base64
                this._vm.$api.main.setAuthorization(response.data.token);
                window.localStorage.setItem('isLogin', 1);
              }
              
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.commit('SET_LOGIN_INFO', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    otp(context, payload) {
      
      const { data, config, apiCancelToken } = payload || {};
      
      // var uuid = new DeviceUUID().get();
      // let uuid = uuidv7()
      
      // data.uuid = uuid
      let name = "mp_uuid=";
      let ca = document.cookie.split(';');
      var user_uuid

      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          user_uuid = c.substring(name.length, c.length);
        }
      }
      
      if(user_uuid){

        data.uuid = user_uuid

      }else{
        user_uuid = uuid.v1()
        document.cookie = "mp_uuid=" + user_uuid;
        data.uuid = user_uuid
      }

      return new Promise((resolve, reject) => {
        mainApi
          .postData({ url: '/auth/login/otp', data, config, apiCancelToken })
          .then((response) => {
            context.commit('SET_TOKEN', response.data.token);
            context.commit('SET_USER_INFO', response.data);
            if (!response.data.tempPassword) {
              this._vm.$cookies.set('token', window.btoa(response.data.token)); // Encode To Base64
              this._vm.$cookies.set('user-info', window.btoa(JSON.stringify(response.data))); // Encode To Base64
              this._vm.$api.main.setAuthorization(response.data.token);
              window.localStorage.setItem('isLogin', 1);
            }
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    logout(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/user/logout', config, apiCancelToken })
          .then((response) => {
            // console.log(response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    setPassword(context, payload) {
      const { data, config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .putData({ url: '/users/password', data, config, apiCancelToken })
          .then((response) => {
            // console.log(response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    
  },
};
