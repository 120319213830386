'use strict';

const global = {
  data() {
    return {
      confirmDialog: { show: false, loading: false, title: '', message: '', buttonText: '', buttonTextColor: '', buttonBGColor: '', action: '', data: {}, callBack: () => {} }
    };
  },
  computed: {
    token() { return this.$store.getters['authentication/getToken']; },
    userInfo() { return this.$store.getters['authentication/getUserInfo']; }
  },
  filters: {
    formatCurrency(value) {
      let number = '';
      if (!isNaN(value)) {
          const numberParts = Number((value + '').replace(/e\+/g, '')).toFixed(2).split('.');
          number = value ? `${numberParts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.${numberParts[1]}` : value;
      } else {
          number = value;
      }
      return number;
    }
  },
  methods: {
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name === name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    generateApiCancelToken() { return `api_cancel_token_${Math.floor(Math.random() * Date.now()).toString(36).slice(2)}`; },
    confirmAction(payload) {
      const { title, message, buttonText, buttonTextColor, buttonBGColor, action, data, callBack } = payload || {};
      this.confirmDialog = { show: true, loading: false, title, message, buttonText, buttonTextColor, buttonBGColor, action, data, callBack };
    },
    formatCurrency(value) {
      return this.$options.filters.formatCurrency(value);
    },
    encryptData(payload) {
      const { data, key } = payload || {},
            encrypted = (data && key) ? this.$cryptojs.AES.encrypt(data, this.$cryptojs.enc.Utf8.parse(key), { mode: this.$cryptojs.mode.ECB, padding: this.$cryptojs.pad.Pkcs7 }).toString() : undefined;
      // console.log({ data, key, encrypted });
      return encrypted;
    },
    decryptData(payload) {
      const { data, key } = payload || {},
            decrypted = (data && key) ? this.$cryptojs.AES.decrypt(data, this.$cryptojs.enc.Utf8.parse(key), { mode: this.$cryptojs.mode.ECB, padding: this.$cryptojs.pad.Pkcs7 }).toString(this.$cryptojs.enc.Utf8) : undefined;
      // console.log({ data, key, decrypted });
      return decrypted;
    }
  }
};

export default global;