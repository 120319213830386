'use strict';

import mainApi from '@/assets/js/api/modules/main';

export default {
  namespaced: true,
  state: {
    transactions_list: {},
  },
  getters: {
    getTransactionsList: (state) => state.transactions_list,
  },
  mutations: {
    SET_TRANSACTIONS_LIST(state, payload) {
      state.transactions_list = payload;
    },
    CLEAR_STATE(state) {
      state.transactions_list = {};
    },
  },
  actions: {
    fetchTransactions(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/payQr', config, apiCancelToken })
          .then((response) => {
            // console.log(response);
            context.commit('SET_TRANSACTIONS_LIST', response.data);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.commit('SET_TRANSACTIONS_LIST', {});
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
    fetchReports(context, payload) {
      const { config, apiCancelToken } = payload || {};
      return new Promise((resolve, reject) => {
        mainApi
          .getData({ url: '/payQr/report', config, apiCancelToken })
          .then((response) => {
            // console.log(response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          });
      });
    },
  },
};
